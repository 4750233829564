import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Button, Collapse, FormControlLabel, Grid, styled, Typography, useTheme } from '@mui/material';
import { analyticsDisplayedList, TYPE_ANALYTICS_MAP_VIEW } from 'common/defines/constants';
import { convertAnalyticNameToMultiLanguage } from 'common/utils/convert';
import { QUERY_KEY } from 'constants/constants';
import { useCheckRTL } from 'hooks/common/useCheckRLF';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { updateInterval } from 'services/analytics/apiAnalyticsConfig.services';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  changeDataPlantStressInterval,
  changeVisibleIntervalStress,
  changeVisibleIntervalStressAllLayers,
  plantStressAnalyticsSelector,
} from 'store/slices/map-view/plantStressAnalytics';
import {
  changeIsDisplay3DCyliner,
  changeIsDisplayBoundary,
  changeLayer3D,
  mapViewSelector,
} from 'store/slices/mapViewSlice';
import { rightBarSelector } from 'store/slices/rightBarSlice';
import { SwitchCustom } from '../../../../../Common/SwitchCustom';
import { useLayerSelectCardStyle } from '../../../../MapViewStyle';
import { IIntervalData } from '../../Category/ChartsAnalytics/ChartsAnlytic.type';
import AbilityDownload from '../AbilityDownload';
import { PolygonLayerTypeRange } from '../PolygonLayerTypeRange';
import SideSurface from '../SideSurface';
import { SwitchLabel } from '../SwitchLabel';
import { SwitchRaster } from '../SwitchRaster';
import TopSurface from '../TopSurface';

export const PlantStressLayer = ({ labelCard }: any) => {
  const queryClient = useQueryClient();
  const { isLayer3D, isDisplayBoundaryLine, isDisplay3DCylinder, isDefaultInterval } = useAppSelector(mapViewSelector);
  const {
    dataPlantStress: data,
    isShowAllLayer,
    dataPlantStressInterval,
    chartDataPlantStressPreview,
  } = useAppSelector(plantStressAnalyticsSelector);
  const [isRaster2, setRaster2] = useState(true);
  const theme = useTheme();
  const [isDisplayExpandLabel, setIsDisplayExpandLabel] = useState(true);
  const { t } = useTranslation();

  const ButtonSubmit = styled(Button)((a) => {
    return {
      width: '100%',
      height: '40px',
      borderRadius: '5px',
      padding: '12px 10px',
      marginBottom: '30px',
      textTransform: 'none',
      border: `1px solid ${theme.palette.primary.main}`,
      color: 'white',
      backgroundColor: `${theme.palette.primary.main}`,

      '&:hover': {
        backgroundColor: '#1C9D57',
        border: `1px solid ${theme.palette.primary.main}`,
        boxShadow: 'none',
      },
      '&:active': {
        boxShadow: 'none',
        backgroundColor: '#1C9D57',
        border: `1px solid ${theme.palette.primary.main}`,
      },
      '&:focus': {
        boxShadow: '0 0 0 0.1rem rgba(70, 255, 70, 0.15)',
        border: `1px solid ${theme.palette.primary.main}`,
      },
    };
  });

  const classes = useLayerSelectCardStyle();
  const dispatch = useAppDispatch();
  const [raster, setRaster] = useState(true);
  const [layerByRange, setLayerByRange] = useState(true);
  const [isChangeColor, setIsChangeColor] = useState(false);
  const [currentData, setCurrentData] = useState<any>(dataPlantStressInterval);

  useEffect(() => {
    setCurrentData(chartDataPlantStressPreview ? chartDataPlantStressPreview.intervalLimit : dataPlantStressInterval);
  }, [chartDataPlantStressPreview, dataPlantStressInterval]);

  const filterDownload = useMemo(() => {
    const downloadData: any[] = [];
    if (!data) {
      return [];
    }
    const allData = data.map((item: any) => {
      return {
        ...item,
        geometry: {
          type: 'MultiPolygon',
          coordinates: item.data,
        },
      };
    });
    allData.forEach((newData: any) => {
      if (newData.visible) {
        downloadData.push(newData);
      }
    });
    return downloadData;
  }, [data]);

  const handleUpdateIntervals = useMutation((data: any) => updateInterval(currentData._id, data), {
    onSuccess: (response: any) => {
      queryClient.invalidateQueries([QUERY_KEY.CHARTS_STRESS_ANALYTIC, isDefaultInterval]).then(() => {});
    },
  });

  const handleChangeColors = (id: number, color: string, typeOfAnalytic: string) => {
    if (currentData?._id && currentData.range) {
      const _data = currentData.range.map((item: IIntervalData) => {
        if (item.from === id) {
          return {
            ...item,
            color,
          };
        }
        return item;
      });
      dispatch(
        changeDataPlantStressInterval({
          ...currentData,
          range: _data,
        })
      );
      setIsChangeColor(true);
      return;
    }
    toast.error('Change settings errors', { toastId: 'changeSettingsError' });
  };
  const { dateAnalyticSelected, levelId } = useAppSelector(mapViewSelector);

  const { analyticName }: { analyticName: any } = useAppSelector(rightBarSelector) || {};
  const isShowLabel = analyticsDisplayedList.includes(analyticName);

  const dataAnalysis: any = queryClient.getQueryData([QUERY_KEY.DATA_ANALYSIS, levelId, dateAnalyticSelected]);

  const amongDataAnalysis = useMemo(() => {
    if (dataAnalysis?.data?.name === 'Stand Count') return dataAnalysis?.data?.length - 1 || 0;
    else return dataAnalysis?.data?.length || 0;
  }, [dataAnalysis]);

  const { isRTL } = useCheckRTL();
  return (
    <>
      {data?.length > 0 && (
        <Grid sx={{ mt: 1 }}>
          <Grid item xs={12} md={12}>
            {amongDataAnalysis > 2 && isShowLabel && (
              <>
                <Button
                  startIcon={isDisplayExpandLabel ? <ExpandMoreIcon /> : <ChevronRightIcon />}
                  onClick={() => setIsDisplayExpandLabel(!isDisplayExpandLabel)}
                  classes={{ root: classes.buttonTextStyle }}>
                  {t('trans.label')}
                </Button>
                <Collapse in={isDisplayExpandLabel} timeout="auto" sx={{ px: 1 }}>
                  <Box sx={{ paddingLeft: '12px' }}>
                    <SwitchLabel />
                  </Box>
                </Collapse>
              </>
            )}
          </Grid>
          {/* Raster List */}
          <Grid item xs={12} md={12}>
            <Button
              startIcon={isRaster2 ? <ExpandMoreIcon /> : <ChevronRightIcon />}
              onClick={() => setRaster2(!isRaster2)}
              classes={{ root: classes.buttonTextStyle }}>
              {t('trans.raster')}
            </Button>
            <Collapse in={isRaster2} timeout="auto" unmountOnExit sx={{ px: 2.5 }}>
              <SwitchRaster analyticType={analyticName} isShowInlineBlock={true} />
            </Collapse>
          </Grid>

          <Button
            startIcon={raster ? <ExpandMoreIcon /> : <ChevronRightIcon />}
            onClick={() => setRaster(!raster)}
            classes={{ root: classes.buttonTextStyle }}>
            {t(convertAnalyticNameToMultiLanguage(labelCard))}
          </Button>
          <Collapse in={raster} timeout="auto" unmountOnExit sx={{ px: 2.5 }}>
            <Grid container>
              <Grid item xs={6} md={6}>
                <FormControlLabel
                  labelPlacement={isRTL ? 'start' : 'end'}
                  control={<SwitchCustom checked={isLayer3D} onClick={() => dispatch(changeLayer3D(!isLayer3D))} />}
                  label={<Typography className={classes.selectText}>{isLayer3D ? '3D' : '2D'}</Typography>}
                />
              </Grid>
              {isLayer3D ? (
                <Grid item xs={6} md={6}>
                  <FormControlLabel
                    labelPlacement={isRTL ? 'start' : 'end'}
                    control={
                      <SwitchCustom
                        checked={isDisplay3DCylinder}
                        onClick={() => dispatch(changeIsDisplay3DCyliner(!isDisplay3DCylinder))}
                      />
                    }
                    label={
                      <Typography className={classes.selectText}>
                        {isDisplay3DCylinder ? '3D Cylinder' : '3D Filled contour '}
                      </Typography>
                    }
                  />
                </Grid>
              ) : (
                <Grid item xs={6} md={6}>
                  <FormControlLabel
                    labelPlacement={isRTL ? 'start' : 'end'}
                    control={
                      <SwitchCustom
                        checked={isDisplayBoundaryLine}
                        onClick={() => dispatch(changeIsDisplayBoundary(!isDisplayBoundaryLine))}
                      />
                    }
                    label={
                      <Typography className={classes.selectText}>
                        {isDisplayBoundaryLine ? t('trans.2d_contour') : t('trans.2d_point')}
                      </Typography>
                    }
                  />
                </Grid>
              )}
              <Grid
                item
                xs={12}
                md={12}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  position: 'relative',
                }}>
                <Box
                  sx={{
                    position: 'absolute',
                    right: 0,
                  }}>
                  {filterDownload.length > 0 && (
                    <AbilityDownload dataPoints={filterDownload} type={TYPE_ANALYTICS_MAP_VIEW.STAND_COUNT} />
                  )}
                </Box>
              </Grid>
            </Grid>
          </Collapse>

          <Grid item xs={12} md={12}>
            <Button
              startIcon={layerByRange ? <ExpandMoreIcon /> : <ChevronRightIcon />}
              onClick={() => setLayerByRange(!layerByRange)}
              classes={{ root: classes.buttonTextStyle }}>
              {t('trans.categories')}
            </Button>
            <Collapse in={layerByRange} timeout="auto" unmountOnExit sx={{ px: 2.5 }}>
              <FormControlLabel
                labelPlacement={isRTL ? 'start' : 'end'}
                control={
                  <SwitchCustom
                    checked={isShowAllLayer}
                    onClick={() => dispatch(changeVisibleIntervalStressAllLayers(!isShowAllLayer))}
                  />
                }
                label={
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                    }}>
                    <Typography className={classes.selectText}>
                      {isShowAllLayer ? t('trans.unselect_all') : t('trans.select_all')}
                    </Typography>
                  </Box>
                }
              />

              {currentData?.range?.length &&
                currentData?.range?.map((item: IIntervalData, index: number) => {
                  return (
                    <PolygonLayerTypeRange
                      key={index}
                      label={item.lable}
                      color={item.color}
                      id={item.from}
                      index={index}
                      visible={item.visible}
                      changeColorAnalyticsLayer={handleChangeColors}
                      changeVisibleAnalyticsLayer={changeVisibleIntervalStress}
                      typeOfAnalytic={TYPE_ANALYTICS_MAP_VIEW.STRESS_TREE}
                    />
                  );
                })}
              {isChangeColor && (
                <Grid container top={2} paddingTop={2} justifyContent={'center'} gap={2} xs={12}>
                  <Grid item xs={4}>
                    <Button
                      color="neutral"
                      variant="outlined"
                      sx={{
                        width: '100%',
                        height: '40px',
                        borderRadius: '5px',
                        p: '12px 10px',
                        mb: '30px',
                        textTransform: 'none',
                        border: `1px solid ${theme.palette.divider}`,
                        color: theme.palette.mode === 'dark' ? '#fff' : '#000',
                      }}
                      onClick={() => {
                        setIsChangeColor(false);
                        queryClient
                          .invalidateQueries([QUERY_KEY.CHARTS_STRESS_ANALYTIC, isDefaultInterval])
                          .then(() => {});
                      }}>
                      Cancel
                    </Button>
                  </Grid>
                  <Grid item xs={4}>
                    <ButtonSubmit
                      onClick={() => {
                        setIsChangeColor(false);
                        handleUpdateIntervals.mutate(currentData.range);
                      }}>
                      Save
                    </ButtonSubmit>
                  </Grid>
                </Grid>
              )}
            </Collapse>
          </Grid>

          <Grid item xs={12} md={12}>
            {isLayer3D && <SideSurface />}
          </Grid>

          <TopSurface />
        </Grid>
      )}
    </>
  );
};
